import { trackPromise } from 'react-promise-tracker'
import qs from 'querystring'
import Event from '#api/Event'

export async function GET(url, body = {}, headers = {}) {
  try {
    const query = qs.stringify(body)

    var respond = await trackPromise(
      fetch(`${url}?${query}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          Accept: 'application/json, text/plain, */*',
          'Cache-Control': 'no-cache',
          ...headers
        }
      })
    )

    let text = await respond.text()
    let json = validJson(text)

    if (respond.ok) {
      if (json.success) return json.data

      return json
    } else if (json.errors) {
      let { errors } = json

      if (errors) {
        var key = Object.keys(errors)[0]
        throw new FetchError(errors[key][0], respond.status, url)
      }
    } else if (json.error) {
      throw new FetchError(json.error, respond.status, url)
    } else {
      throw new FetchError(text, respond.status, url)
    }
  } catch (err) {
    /*if(err.status !== 401 && err.status !== 500)
			var error = JSON.parse(err.message)
			toast.error(error.message);*/
    if (err.status === 401) Event.emit('failed_auth')
    throw err
  }
}

export async function GETUNTRACK(url, body = {}, headers = {}) {
  try {
    const query = qs.stringify(body)

    var respond = await fetch(`${url}?${query}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        Accept: 'application/json, text/plain, */*',
        'Cache-Control': 'no-cache',
        ...headers
      }
    })

    let text = await respond.text()
    let json = validJson(text)

    if (respond.ok) {
      if (json.success) return json.data

      return json
    } else if (json.errors) {
      let { errors } = json

      if (errors) {
        var key = Object.keys(errors)[0]
        throw new FetchError(errors[key][0], respond.status, url)
      }
    } else if (json.error) {
      throw new FetchError(json.error, respond.status, url)
    } else {
      throw new FetchError(text, respond.status, url)
    }
  } catch (err) {
    /*if(err.status !== 401 && err.status !== 500)
			var error = JSON.parse(err.message)
			toast.error(error.message);*/
    if (err.status === 401) Event.emit('failed_auth')
    throw err
  }
}

export async function POST(url, data, headers = {}) {
  try {
    var respond = await trackPromise(
      fetch(url, {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          Accept: 'application/json, text/plain, */*',
          'Cache-Control': 'no-cache',
          ...headers
        }
      })
    )

    let text = await respond.text()
    let json = validJson(text)

    if (respond.ok) {
      return JSON.parse(text)
    } else if (json.errors) {
      let { errors } = json

      if (errors) {
        throw new ValidationError(errors, respond.status, url)
        // var key = Object.keys(errors)[0];
        // throw new FetchError( errors[key][0], respond.status, url );
      }
    } else if (json.error) {
      throw new FetchError(json.error, respond.status, url)
    } else {
      throw new FetchError(text, respond.status, url)
    }
  } catch (err) {
    if (err.status === 401) Event.emit('failed_auth')
    throw err
  }
}

export async function POSTUNTRACK(url, data, headers = {}) {
  try {
    var respond = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        Accept: 'application/json, text/plain, */*',
        'Cache-Control': 'no-cache',
        ...headers
      }
    })

    let text = await respond.text()
    let json = validJson(text)

    if (respond.ok) {
      return JSON.parse(text)
    } else if (json.errors) {
      let { errors } = json

      if (errors) {
        throw new ValidationError(errors, respond.status, url)
        // var key = Object.keys(errors)[0];
        // throw new FetchError( errors[key][0], respond.status, url );
      }
    } else if (json.error) {
      throw new FetchError(json.error, respond.status, url)
    } else {
      throw new FetchError(text, respond.status, url)
    }
  } catch (err) {
    if (err.status === 401) Event.emit('failed_auth')
    throw err
  }
}

export async function PATCH(url, data, headers = {}) {
  try {
    var respond = await trackPromise(
      fetch(url, {
        method: 'PATCH',
        mode: 'cors',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          Accept: 'application/json, text/plain, */*',
          'Cache-Control': 'no-cache',
          ...headers
        }
      })
    )

    let text = await respond.text()
    let json = validJson(text)

    if (respond.ok) {
      return JSON.parse(text)
    } else if (json.errors) {
      let { errors } = json

      if (errors) {
        var key = Object.keys(errors)[0]
        throw new FetchError(errors[key][0], respond.status, url)
      }
    } else if (json.error) {
      throw new FetchError(json.error, respond.status, url)
    } else {
      throw new FetchError(text, respond.status, url)
    }
  } catch (err) {
    if (err.status === 401) Event.emit('failed_auth')
    throw err
  }
} 

export async function PATCHUNTRACK(url, data, headers = {}) {
  try {
    var respond = await fetch(url, {
      method: 'PATCH',
      mode: 'cors',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        Accept: 'application/json, text/plain, */*',
        'Cache-Control': 'no-cache',
        ...headers
      }
    })

    let text = await respond.text()
    let json = validJson(text)

    if (respond.ok) {
      return JSON.parse(text)
    } else if (json.errors) {
      let { errors } = json

      if (errors) {
        var key = Object.keys(errors)[0]
        throw new FetchError(errors[key][0], respond.status, url)
      }
    } else if (json.error) {
      throw new FetchError(json.error, respond.status, url)
    } else {
      throw new FetchError(text, respond.status, url)
    }
  } catch (err) {
    if (err.status === 401) Event.emit('failed_auth')
    throw err
  }
}

export async function DELETE(url, data, headers = {}) {
  try {
    var respond = await trackPromise(
      fetch(url, {
        method: 'DELETE',
        mode: 'cors',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          Accept: 'application/json, text/plain, */*',
          'Cache-Control': 'no-cache',
          ...headers
        }
      })
    )

    let text = await respond.text()
    let json = validJson(text)

    if (respond.ok) {
      return JSON.parse(text)
    } else if (json.errors) {
      let { errors } = json

      if (errors) {
        var key = Object.keys(errors)[0]
        throw new FetchError(errors[key][0], respond.status, url)
      }
    } else if (json.error) {
      throw new FetchError(json.error, respond.status, url)
    } else {
      throw new FetchError(text, respond.status, url)
    }
  } catch (err) {
    if (err.status === 401) Event.emit('failed_auth')
    throw err
  }
}

export async function POSTFORM(url, form, headers = {}) {
  try {
    var respond = await trackPromise(
      fetch(url, {
        method: 'POST',
        mode: 'cors',
        body: form,
        headers: {
          Accept: 'application/json',
          //'Content-Type': 'multipart/form-data',
          ...headers
        }
      })
    )

    let text = await respond.text()
    let json = validJson(text)

    if (respond.ok) {
      if (json.success) return json.data

      return json
    } else if (json.errors) {
      let { errors } = json

      if (errors) {
        throw new ValidationError(errors, respond.status, url)
        // var key = Object.keys(errors)[0];
        // throw new FetchError(errors[key][0], respond.status, url );
      }
    } else if (json.error) {
      throw new FetchError(json.error, respond.status, url)
    } else {
      throw new FetchError(text, respond.status, url)
    }
  } catch (err) {
    // if(err.status !== 401 && err.status !== 500)
    // 	toast.error(err.message);
    if (err.status === 401) Event.emit('failed_auth')
    throw err
  }
}

export async function GETBLOBURL(url, body = {}, headers = {}) {
  try {

    var respond = await trackPromise(
      fetch(`${url}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json;charSet=UTF-8',
          Accept: 'application/json, text/plain, */*',
          'Cache-Control': 'no-cache',
          ...headers
        }
      })
    )

    if (!respond.ok) {
      let text = await respond.text()
      let json = validJson(text)
      let parsedText = JSON.parse(text)

      throw json
    }
    const contentTitle = respond.headers.get('Content-Disposition')
    const contentType = respond.headers.get('Content-Type')
    const blob = await respond.blob()
    const blobLink = (window.webkitURL || window.URL).createObjectURL(blob)
    if (contentTitle && contentTitle.includes('filename=')) {
      const filename = contentTitle.split('filename=')[1]
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob)
        return
      }
      let anchor = document.createElement('a')
      anchor.download = filename
      anchor.href = blobLink
      anchor.dataset.downloadurl = [contentType, anchor.download, anchor.href].join(':')
      anchor.click()
      ;(window.webkitURL || window.URL).revokeObjectURL(blobLink)
    } else {
      window.open(blobLink)
    }
  } catch (err) {
    /*if(err.status !== 401 && err.status !== 500)
			var error = JSON.parse(err.message)
			toast.error(error.message);*/
    throw err
  }
}

function validJson(str) {
  try {
    return JSON.parse(str)
  } catch (e) {
    return false
  }
}

class FetchError extends Error {
  constructor(message, status, url) {
    super()

    this.name = 'Fetch'
    this.message = message
    this.status = status
    this.url = url
  }
}

class ValidationError extends Error {
  constructor(errors, status, url) {
    super()

    this.name = 'Validation'
    this.errors = errors
    this.status = status
    this.url = url
  }
}
