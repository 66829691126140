import SyncStorage, { SessionStorage } from './SyncStorage'
import { GET, POST, POSTFORM, DELETE } from './Fetch'
import moment from 'moment-timezone'
import jwt_decode from 'jwt-decode'

import Event from '#api/Event'
import basepath from '#components/basepath'

export default class Api {
  constructor() {
    this.prefix = ''

    this.url = process.env.NEXT_PUBLIC_API_URL || ''

    //this.checkAuth();
    // Event.on('refresh_token', this.tokenExpired.bind(this));
  }

  // async tokenExpired() {
  //     try {
  //         Event.emit('update_auth');
  //     } catch (ex) {
  //         console.log(ex);
  //     }
  // }

  async checkAuth(noGuest = false) {
    try {
      await this.getAuth()

      if (!this.token) throw Error('No Token')

      var decoded = jwt_decode(this.token || '')

      if (noGuest && Object.keys(decoded).includes('isLoggedIn') && decoded.isLoggedIn === false) {
        throw Error('Not logged in')
      }

      if (
        moment(parseInt(decoded.exp) * 1000).isBetween(
          moment().add(1, 'days'),
          moment().add(12, 'hour')
        )
      )
        await this.refresh()
      else if (moment(parseInt(decoded.exp) * 1000).isBefore(moment())) {
        throw Error('Expired Token')
      }

      Event.emit('update_auth')

      // token is still consumable
      return true
    } catch (ex) {
      Event.emit('failed_auth')
      // cannot refresh
      return false
    }
  }

  async getAuth() {
    var prefix = this.prefix
    var res = await SessionStorage.multiGet([`${prefix}@token`, `${prefix}@user`])

    if (!res[0][1]) res = await SyncStorage.multiGet([`${prefix}@token`, `${prefix}@user`])

    this.token = res[0][1]
    this.user = res[1][1] ? JSON.parse(res[1][1]) : null
  }

  async storeAuth(access_token, user = null, remember) {
    var prefix = this.prefix

    await SyncStorage.setItem(`${prefix}@token`, access_token)
    await SyncStorage.setItem(`${prefix}@user`, JSON.stringify(user))

    this.token = access_token
    this.user = user
  }

  async login(username, password, remember) {
    try {
      var { token, user } = await POST(`${this.url}/api/auth/login`, {
        username,
        password,
        scope: 'customer'
      })

      this.storeAuth(token, user, remember)

      this.getAuth()

      return true
    } catch (ex) {
      throw ex
    }
  }

  async docLogin({ access_token, remember }) {
    try {
      var { token, user } = await POST(`${this.url}/api/auth/login/doc`, {
        access_token
      })

      this.storeAuth(token, user, remember)

      this.getAuth()

      return true
    } catch (ex) {
      throw ex
    }
  }

  async socialLogin(provider, access_token) {
    var { token, user } = await POST(`${this.url}/api/auth/login/${provider}`, {
      token: access_token
    })

    this.storeAuth(token, user)

    return true
  }

  async register(full_name, username, email, phone, password, password_confirmation) {
    return POST(`${this.url}/api/auth/register`, {
      full_name,
      username,
      email,
      phone,
      password,
      password_confirmation
    })
  }

  async logout() {
    var prefix = this.prefix
    await SyncStorage.multiRemove([`${prefix}@token`, `${prefix}@user`])
  }

  async refresh() {
    var { token, user } = await POST(
      `${this.url}/api/auth/refresh`,
      {},
      { Authorization: `Bearer ${this.token}` }
    )

    this.storeAuth(token, user)

    return true
  }

  forgotPassword(username) {
    return POST(`${this.url}/api/auth/forgot`, {
      username
    })
  }

  changePassword({ password, password_confirmation, token }) {
    return POST(
      `${this.url}/api/auth/password`,
      { password, password_confirmation },
      { Authorization: `Bearer ${token}` }
    )
  }

  changePasswordProfile({ password, new_password, new_password_confirmation }) {
    return POST(
      `${this.url}/api/auth/password/profile`,
      { password, new_password, new_password_confirmation },
      { Authorization: `Bearer ${this.token}` }
    )
  }

  verifyEmail({ token }) {
    return POST(`${this.url}/api/auth/verify`, {}, { Authorization: `Bearer ${token}` })
  }

  reverifyEmail() {
    return POST(`${this.url}/api/auth/reverify`, {}, { Authorization: `Bearer ${this.token}` })
  }

  // Image
  getImage(image_path) {
    if (image_path && process.env.NEXT_PUBLIC_CDNPATH)
      return `${process.env.NEXT_PUBLIC_CDNPATH}${image_path}` //`${this.url}/api/image/${image_id}`;
    else return basepath('/images/c01.jpg')
  }
}
