import localforage from 'localforage'
class Storage {
  static async setItem(key, value, ttl = 2.628e9) {
    const now = new Date()

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
      value: value,
      expiry: now.getTime() + ttl
    }

    await localforage.setItem(key, JSON.stringify(item))
  }

  static async getItem(key) {
    const itemStr = await localforage.getItem(key)
    // if the item doesn't exist, return null
    if (!itemStr) {
      return null
    }
    const item = JSON.parse(itemStr)
    const now = new Date()
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      await localforage.removeItem(key)
      return null
    }
    return item.value
  }

  static async removeItem(key) {
    await localforage.removeItem(key)
  }

  static async multiGet(keys) {
    let values = []
    for (let key of keys) {
      values.push([key, await Storage.getItem(key)])
    }

    return values
  }

  static async multiSet(keyValuePairs) {
    for (let pair of keyValuePairs) {
      await localforage.setItem(pair[0], pair[1])
    }
  }

  static async multiRemove(keys) {
    for (let key of keys) {
      await localforage.removeItem(key)
    }
  }

  static async clear() {
    await localforage.clear()
  }
}

class MockStorage {
  static setItem(key, value) {}

  static getItem(key) {
    return null
  }

  static removeItem(key) {}

  static multiGet(keys) {
    return []
  }

  static multiSet(keyValuePairs) {}

  static multiRemove(keys) {}

  static clear() {}
}

let SyncStorage = (function () {
  return typeof window !== `undefined` ? Storage : MockStorage
})()

let SessionStorage = (function () {
  return typeof window !== `undefined` ? Storage : MockStorage
})()

export default SyncStorage
export { SessionStorage }
